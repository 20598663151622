import axios from 'axios';
import globals from '../../globals';

const state = {
  smartPaths: [],
  selectedSmartPath: null,
  smartPath: '',
  path: [],
  curr: '0',
  answerStack: [],
  smartPathTransactions: [],
  smartPathTransactionsPaginatorTotal: 0,
  smartPathTransactionsSelectedSmartPathName: '',
  transactionHistory: [],
  selectedTransaction: null,
  smartPathReferralForm: null,
  smartPathSpecialty: null,
  smartPathReferralTransactionId: null,
  selectedSmartPathReferralContributor: null,
  smartPathReferralSurveyFlag: null,
  availableContributors: [],
  selectedContributor: null,
  linkableSmartPaths: [],
  orgUnitUsersOptedIntoSmartPath: [],
  deletedNodesToCheck: [],
  selectedSmartPathNodeAnalytic: null,
};

const getters = {
  smartPaths: (state) => {
    return state.smartPaths;
  },
  selectedSmartPath: (state) => {
    return state.selectedSmartPath;
  },
  smartPath: (state) => {
    return state.smartPath;
  },
  path: (state) => {
    return state.path;
  },
  curr: (state) => {
    return state.curr;
  },
  answerStack: (state) => {
    return state.answerStack;
  },
  smartPathTransactions: (state) => {
    return state.smartPathTransactions;
  },
  smartPathTransactionsPaginatorTotal: (state) => {
    return state.smartPathTransactionsPaginatorTotal;
  },
  smartPathTransactionsSelectedSmartPathName: (state) => {
    return state.smartPathTransactionsSelectedSmartPathName;
  },
  transactionHistory: (state) => {
    return state.transactionHistory;
  },
  selectedTransaction: (state) => {
    return state.selectedTransaction;
  },
  smartPathReferralForm: (state) => {
    return state.smartPathReferralForm;
  },
  smartPathSpecialty: (state) => {
    return state.smartPathSpecialty;
  },
  smartPathReferralTransactionId: (state) => {
    return state.smartPathReferralTransactionId;
  },
  selectedSmartPathReferralContributor: (state) => {
    return state.selectedSmartPathReferralContributor;
  },
  smartPathReferralSurveyFlag: (state) => {
    return state.smartPathReferralSurveyFlag;
  },
  availableContributors: (state) => {
    return state.availableContributors;
  },
  selectedContributor: (state) => {
    return state.selectedContributor;
  },
  linkableSmartPaths: (state) => {
    return state.linkableSmartPaths;
  },
  orgUnitUsersOptedIntoSmartPath: (state) => {
    return state.orgUnitUsersOptedIntoSmartPath;
  },
  deletedNodesToCheck: (state) => {
    return state.deletedNodesToCheck;
  },
  selectedSmartPathNodeAnalytic: (state) => {
    return state.selectedSmartPathNodeAnalytic;
  },
};

const actions = {
  getSmartPath(context, smartPathId) {
    return new Promise((resolve, reject) => {
      axios
        .get(globals.APIs.getSmartPathUrl(), {
          headers: context.rootGetters.setHeaderWithAuth,
          params: {
            smart_path_id: smartPathId,
          },
        })
        .then((response) => {
          context.commit('setSelectedSmartPath', response.data.data);
          resolve('success');
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getSmartPathForSurvey(context, smartPathId) {
    return new Promise((resolve, reject) => {
      axios
        .get(globals.APIs.getSmartPathUrl(smartPathId), {
          headers: context.rootGetters.setHeaderWithAuth,
        })
        .then((response) => {
          context.commit('setSmartPath', response.data.data);
          resolve('success');
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getSmartPathsOfUser(context, userId) {
    return new Promise((resolve, reject) => {
      axios
        .get(globals.APIs.getUsersSmartPathsUrl(userId), {
          headers: context.rootGetters.setHeaderWithAuth,
        })
        .then((response) => {
          context.commit('setSmartPaths', response.data.data);
          resolve('success');
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getPublicSmartPaths(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .get(globals.APIs.getPublicSmartPathsUrl(), {
          params: data,
          headers: context.rootGetters.setHeaderWithAuth,
        })
        .then((response) => {
          context.commit('setSmartPaths', response.data.data);
          resolve('success');
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  // use this for creating and saving smartpaths
  saveSmartPath(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(globals.APIs.saveSmartPathUrl(), data, {
          headers: context.rootGetters.setHeaderWithAuth,
        })
        .then((response) => {
          context.commit('setSelectedSmartPath', response.data.data);
          resolve('success');
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  saveSmartPathTransaction(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(globals.APIs.saveSmartPathTransactionUrl(), data, {
          headers: context.rootGetters.setHeaderWithAuth,
        })
        .then((response) => {
          context.commit('setSelectedTransaction', response.data.data);
          resolve('success');
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getSmartPathTransactions(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(data.url, data, {
          headers: context.rootGetters.setHeaderWithAuth,
        })
        .then((response) => {
          context.commit('setSmartPathTransactionsSelectedSmartPathName', response.data.smart_path_name);
          context.commit('setSmartPathTransactionsPaginatorTotal', response.data.total);
          context.commit('setSmartPathTransactions', response.data.data);
          resolve('success');
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  downloadSmartPathNodeMaterial(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(globals.APIs.downloadSmartPathNodeMaterialUrl(), data, {
          headers: context.rootGetters.setHeaderWithAuth,
          responseType: 'blob',
        })
        .then((response) => {
          let fileName = data.material_name + '.pdf';
          context
            .dispatch('viewDownloadedFile', { downloadedFileContent: response.data, fileName: fileName })
            .then(() => {
              resolve(true);
            })
            .catch((error) => {
              reject(error);
            });
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getSmartPathTransactionHistoryOfUser(context, userId) {
    return new Promise((resolve, reject) => {
      axios
        .get(globals.APIs.getSmartPathTransactionHistoryUrl(userId), {
          headers: context.rootGetters.setHeaderWithAuth,
        })
        .then((response) => {
          context.commit('setTransactionHistory', response.data.data);
          resolve('success');
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getSmartPathTransactionById(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(globals.APIs.getSmartPathTransactionUrl(), data, {
          headers: context.rootGetters.setHeaderWithAuth,
        })
        .then((response) => {
          context.commit('setSelectedTransaction', response.data.data);
          resolve('success');
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  updateTransactionStatus(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(globals.APIs.updateTransactionStatusUrl(), data, {
          headers: context.rootGetters.setHeaderWithAuth,
        })
        .then((response) => {
          context.commit('setSelectedTransaction', response.data.data);
          resolve('success');
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  downloadSmartPathResult(context, transactionId) {
    return new Promise((resolve, reject) => {
      axios
        .get(globals.APIs.downloadSmartPathResultUrl(), {
          params: {
            transaction_id: transactionId,
          },
          headers: context.rootGetters.setHeaderWithAuth,
          responseType: 'blob',
        })
        .then((response) => {
          let fileName = 'PathwayResult-' + transactionId + '.pdf';
          context
            .dispatch('viewDownloadedFile', { downloadedFileContent: response.data, fileName: fileName })
            .then(() => {
              resolve(true);
            })
            .catch((error) => {
              reject(error);
            });
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  faxSmartPathResult(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .get(globals.APIs.faxSmartPathResultUrl(), {
          params: data,
          headers: context.rootGetters.setHeaderWithAuth,
        })
        .then((response) => {
          resolve('success');
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  toggleTransactionSeen(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(globals.APIs.toggleTransactionSeenUrl(), data, {
          headers: context.rootGetters.setHeaderWithAuth,
        })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getAvailableContributors(context, smartPathId) {
    return new Promise((resolve, reject) => {
      axios
        .get(globals.APIs.getAvailableContributorsUrl(smartPathId), {
          headers: context.rootGetters.setHeaderWithAuth,
        })
        .then((response) => {
          context.commit('setAvailableContributors', response.data.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  attachContributors(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(globals.APIs.attachContributorsUrl(), data, {
          headers: context.rootGetters.setHeaderWithAuth,
        })
        .then((response) => {
          context.commit('setSelectedSmartPath', response.data.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  detachContributors(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(globals.APIs.detachContributorsUrl(), data, {
          headers: context.rootGetters.setHeaderWithAuth,
        })
        .then((response) => {
          context.commit('setSelectedSmartPath', response.data.data);
          // context.commit('setAvailableContributors', response.data.data.contributors);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  attachSmartPathOrgUnits(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(globals.APIs.attachSmartPathOrgUnitsUrl(), data, {
          headers: context.rootGetters.setHeaderWithAuth,
        })
        .then((response) => {
          context.commit('setSelectedSmartPath', response.data.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  detachSmartPathOrgUnit(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(globals.APIs.detachSmartPathOrgUnitUrl(), data, {
          headers: context.rootGetters.setHeaderWithAuth,
        })
        .then((response) => {
          context.commit('setSelectedSmartPath', response.data.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getLinkableSmartPaths(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(globals.APIs.getLinkableSmartPathsUrl(), data, {
          headers: context.rootGetters.setHeaderWithAuth,
        })
        .then((response) => {
          context.commit('setLinkableSmartPaths', response.data.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  linkSmartPathTransaction(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(globals.APIs.linkSmartPathTransactionUrl(), data, {
          headers: context.rootGetters.setHeaderWithAuth,
        })
        .then((response) => {
          context.commit('setSelectedTransaction', response.data.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  saveTransactionTriageMessage(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(globals.APIs.saveTransactionTriageMessageUrl(), data, {
          headers: context.rootGetters.setHeaderWithAuth,
        })
        .then((response) => {
          context.commit('setSelectedTransaction', response.data.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  saveTransactionMessageForTriage(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(globals.APIs.saveTransactionMessageForTriageUrl(), data, {
          headers: context.rootGetters.setHeaderWithAuth,
        })
        .then((response) => {
          context.commit('setSelectedTransaction', response.data.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getOrgUnitUsersOptedIntoSmartPath(context, org_unit_head_id) {
    return new Promise((resolve, reject) => {
      axios
        .get(globals.APIs.getOrgUnitUsersOptedIntoSmartPathUrl(), {
          headers: context.rootGetters.setHeaderWithAuth,
          params: { org_unit_head_id: org_unit_head_id },
        })
        .then((response) => {
          context.commit('setOrgUnitUsersOptedIntoSmartPath', response.data.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getSelectedSmartPathNodeAnalytic(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .get(globals.APIs.getSelectedSmartPathNodeAnalyticUrl(), {
          headers: context.rootGetters.setHeaderWithAuth,
          params: data,
        })
        .then((response) => {
          context.commit('setSelectedSmartPathNodeAnalytic', response.data.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  uploadSmartPathMaterial(context, data) {
    return new Promise((resolve, reject) => {
      let formData = new FormData();
      formData.append('smart_path_node_id', data.smart_path_node_id);
      formData.append('material_type', data.material_type);
      formData.append('material_name', data.material_name);
      formData.append('url', data.url);
      data.files.forEach((file) => {
        formData.append('files[]', file);
      });

      axios
        .post(globals.APIs.uploadSmartPathMaterialUrl(), formData, {
          headers: { ...context.rootGetters.setHeaderWithAuth, 'Content-Type': 'multipart/form-data' },
        })
        .then((response) => {
          // push in the created material to the node
          context.commit('appendUploadedMaterialToSmartPathNode', response.data.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  deleteSmartPathMaterial(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(globals.APIs.deleteSmartPathMaterialUrl(), data, {
          headers: context.rootGetters.setHeaderWithAuth,
        })
        .then((response) => {
          // filter out the deleted material from the node
          const nodeIndex = context.state.selectedSmartPath.nodes.findIndex((node) => node.id === data.smart_path_node_id);
          if (nodeIndex !== -1) {
            context.state.selectedSmartPath.nodes[nodeIndex].materials = context.state.selectedSmartPath.nodes[nodeIndex].materials.filter((material) => material.id !== data.material_id);
          }
          context.commit('removeMaterialFromSmartPathNode', { material_id: data.material_id, smart_path_node_id: data.smart_path_node_id });
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getSmartPathRecommendation(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .get(globals.APIs.getSmartPathRecommendationUrl(), {
          params: data,
          headers: context.rootGetters.setHeaderWithAuth,
        })
        .then((response) => {
          resolve(response.data.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  completeSmartPathTransaction(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(globals.APIs.completeSmartPathTransactionUrl(), data, {
          headers: context.rootGetters.setHeaderWithAuth,
        })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};

const mutations = {
  setSmartPaths(state, data) {
    state.smartPaths = data;
  },
  setSelectedSmartPath(state, data) {
    state.selectedSmartPath = data;
  },
  setSmartPath(state, data) {
    state.smartPath = data;
    state.smartPath.smart_path = JSON.parse(data.smart_path);
  },
  setPath(state, data) {
    state.path = data;
  },
  setCurr(state, data) {
    state.curr = data;
  },
  pushAnswerStack(state, data) {
    state.answerStack.push(data);
  },
  popAnswerStack(state) {
    // for terminal nodes we push two things, so pop twice for them
    // do it up here to maintain the logic of the .length-1 below
    if (state.smartPath.smart_path[state.curr]['node_type'] == 'terminal') {
      state.answerStack.pop();
    }

    state.curr = state.answerStack[state.answerStack.length - 1].id;
    state.answerStack.pop();
  },
  popAllAfterIndexAnswerStack(state, data, question, value) {
    let index = 0;
    while (index < state.answerStack.length && state.answerStack[index].id != data) {
      index++;
    }
    if (index != state.answerStack.length) {
      state.answerStack[index].question = question;
      state.answerStack[index].value = value;
      state.answerStack.length = index;
      state.curr = data;
    }
  },
  resetAnswerStack(state) {
    state.answerStack = [];
  },
  resetCurr(state) {
    state.curr = '0';
  },
  setAnswerStack(state, data) {
    state.answerStack = data;
  },
  setSmartPathTransactionsPaginatorTotal(state, data) {
    state.smartPathTransactionsPaginatorTotal = data;
  },
  setSmartPathTransactions(state, data) {
    state.smartPathTransactions = data;
  },
  setTransactionHistory(state, data) {
    state.transactionHistory = data;
  },
  setSelectedTransaction(state, data) {
    state.selectedTransaction = data;
  },
  setSmartPathReferralForm(state, data) {
    state.smartPathReferralForm = data;
  },
  setSmartPathSpecialty(state, data) {
    state.smartPathSpecialty = data;
  },
  setSmartPathReferralTransactionId(state, data) {
    state.smartPathReferralTransactionId = data;
  },
  setSelectedSmartPathReferralContributor(state, data) {
    state.selectedSmartPathReferralContributor = data;
  },
  setSmartPathTransactionsSelectedSmartPathName(state, data) {
    state.smartPathTransactionsSelectedSmartPathName = data;
  },
  setSmartPathReferralSurveyFlag(state, data) {
    state.smartPathReferralSurveyFlag = data;
  },
  setAvailableContributors(state, data) {
    state.availableContributors = data;
  },
  setSelectedContributor(state, data) {
    state.selectedContributor = data;
  },
  setLinkableSmartPaths(state, data) {
    state.linkableSmartPaths = data;
  },
  setOrgUnitUsersOptedIntoSmartPath(state, data) {
    state.orgUnitUsersOptedIntoSmartPath = data;
  },
  setDeletedNodesToCheck(state, data) {
    state.deletedNodesToCheck = data;
  },
  appendDeletedNodesToCheck(state, data) {
    state.deletedNodesToCheck.push(data);
  },
  setSelectedSmartPathNodeAnalytic(state, data) {
    state.selectedSmartPathNodeAnalytic = data;
  },
  appendUploadedMaterialToSmartPathNode(state, data) {
    if (!data || data.length === 0) {
      return;
    }
    // Find the node in selectedSmartPath that matches the smart_path_node_id
    const targetNode = state.selectedSmartPath.nodes.find((node) => node.id === data[0].smart_path_node_id);

    // Initialize materials array if it doesn't exist
    if (!targetNode.materials) {
      targetNode.materials = [];
    }

    // Append each new material to the node's materials array
    data.forEach((material) => {
      targetNode.materials.push(material);
    });
  },
  removeMaterialFromSmartPathNode(state, data) {
    const findNodeAndUpdateMaterials = (node, targetId) => {
      if (!node) return false;

      if (node.id === targetId) {
        if (!node.materials) node.materials = [];
        node.materials = node.materials.filter((material) => material.id !== data.material_id);
        return true;
      }

      return node.children?.some((child) => findNodeAndUpdateMaterials(child, targetId));
    };

    let root = state.selectedSmartPath.nodes.find((node) => node.type === 'root');
    if (root) {
      findNodeAndUpdateMaterials(root, data.smart_path_node_id);
    }
  },
  // you have to push and pop nodes to selectedSmartPath so they get checked / not checked respectively for validation_errors
  removeNodeFromSelectedSmartPath(state, data) {
    state.selectedSmartPath.nodes = state.selectedSmartPath.nodes.filter((node) => node.id !== data);
  },
  addNodeToSelectedSmartPath(state, data) {
    state.selectedSmartPath.nodes.push(data);
  },
  updateNodeValidationOnSelectedSmartPath(state, node) {
    if (node.id) {
      const nodeToUpdate = state.selectedSmartPath.nodes.find((n) => n.id === node.id);
      // Find the node in the selectedSmartPath nodes and update it
      if (nodeToUpdate) {
        // Create a new node object with the updated validation_errors
        Object.assign(nodeToUpdate, {
          ...nodeToUpdate,
          validation_errors: node.validation_errors,
        });
      }
    }
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
